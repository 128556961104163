import React, { useContext, useState } from "react"
import "./Contact.scss"
import { ReactComponent as SuccessIcon } from "../../assets/icons/success-icon.svg"
import { useForm } from "react-hook-form"
import { contactFormFields } from "../../data/contact-form-data"
import { Spinner } from "react-bootstrap"
import Collapse from "react-bootstrap/Collapse"
import { LanguageContext } from "../../contexts/languageContext"
import { translateText } from "../../utils/translateText"

const API_URL = process.env.GATSBY_API_URL

const MESSAGE_DISAPPEAR_MILLISECONDS = 10000

const CONTACTS_ENDPOINT = "contacts"

/* A custom menu component.
@param mode - 'black' or 'white'; default white
@param message - object, eg. {message: ..., type: 'error' or 'info'} or null
*/
const Contact = ({ mode, message, withHeadingOne }) => {
  const languageContext = useContext(LanguageContext)
  const currentLanguage = languageContext[0]
  const googleSheetData = languageContext[2]

  const { register, handleSubmit, errors, reset } = useForm()
  const [showMessage, setShowMessage] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)

  const onSubmit = async data => {
    setShowSpinner(true)

    let params = new URLSearchParams()
    for (const name in data) {
      params.append(name, data[name])
    }
    params.append("name_de", data.name)

    try {
      await fetch(`${API_URL}/${CONTACTS_ENDPOINT}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: params,
      })

      setTimeout(() => {
        setShowMessage(true)
        setShowSpinner(false)

        setTimeout(() => {
          setShowMessage(false)
          reset()
        }, MESSAGE_DISAPPEAR_MILLISECONDS)
      }, 1000)
    } catch (err) {}
  }

  const selectedMode = mode ? mode : "white"
  const className = "contact-form contact-form-" + selectedMode
  const messageObject = {
    messageText: message?.message,
    messageType: message?.type,
  }

  const inputFieldErrorClassName = "contact-form-content-inputs-field-error"

  return (
    <Collapse in={showMessage}>
      <div
        className={className}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {withHeadingOne ? (
          <h1 className="title">{`${translateText(
            "Contact us",
            currentLanguage.fullTitle,
            googleSheetData
          )}`}</h1>
        ) : (
          <h2 className="title">{`${translateText(
            "Contact us",
            currentLanguage.fullTitle,
            googleSheetData
          )}`}</h2>
        )}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="contact-form-content-inputs"
        >
          {contactFormFields.map(field => {
            const {
              name,
              placeholder,
              type,
              required,
              maxLength,
              pattern,
            } = field
            const currentFieldErrors = errors[name]

            return (
              <div className="input-container" key={name}>
                {type === "textarea" ? (
                  <textarea
                    name={name}
                    placeholder={translateText(
                      placeholder,
                      currentLanguage.fullTitle,
                      googleSheetData
                    )}
                    ref={register({
                      required: required,
                      maxLength: maxLength,
                    })}
                    className={
                      currentFieldErrors ? inputFieldErrorClassName : ""
                    }
                  />
                ) : (
                  <input
                    name={name}
                    placeholder={translateText(
                      placeholder,
                      currentLanguage.fullTitle,
                      googleSheetData
                    )}
                    ref={register({
                      required: required,
                      maxLength: { maxLength },
                      pattern: pattern,
                    })}
                    className={
                      currentFieldErrors ? inputFieldErrorClassName : ""
                    }
                  />
                )}
                <span className="field-error-message">
                  {`${
                    currentFieldErrors?.message
                      ? translateText(
                          currentFieldErrors?.message,
                          currentLanguage.fullTitle,
                          googleSheetData
                        )
                      : ""
                  }`}
                </span>
              </div>
            )
          })}
          <button type="submit">
            {`${translateText(
              "Send message",
              currentLanguage.fullTitle,
              googleSheetData
            )}`}
            {showSpinner ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="spinner"
              />
            ) : null}
          </button>
        </form>

        {message && showMessage && (
          <div className="contact-form-message">
            <span>{messageObject.messageText}</span>
            {messageObject.messageType === "success" ? <SuccessIcon /> : <></>}
          </div>
        )}
      </div>
    </Collapse>
  )
}

export default Contact
