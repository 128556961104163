const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

export const contactFormFields = [
  {
    name: "name",
    placeholder: "Name",
    type: "input",
    required: true,
  },
  {
    name: "email",
    placeholder: "Email",
    type: "input",
    required: true,
    maxLength: { value: 255, message: "Max length is 255" },
    pattern: {
      value: emailRegex,
      message: "Invalid email",
    },
  },
  {
    name: "message",
    placeholder: "Message",
    type: "textarea",
    required: true,
    maxLength: { value: 2000, message: "Max length is 2000" },
  },
]
